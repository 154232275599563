import React, { useEffect, useState } from "react";
import { Button, Container, Grid, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "../../components/avatar/avatar";
import Text from "../../components/data-display/text";
import ReadMore from "../../components/data-display/read-more";
import Insurance from "../../images/badges/insurance.png";
import ID from "../../images/badges/id_check.png";
import BackgroundCheck from "../../images/badges/bg_check.png";
import GoogleIcon from "../../images/icons/google.png";
import { auth } from "../../utils/firebase.utils";
import FacebookIcon from "../../images/icons/facebook.png";
import Icon from "react-eva-icons";
import ReviewsBox from "../../components/public-profile/ReviewBox";
import { useParams, Router, useLocation } from "@reach/router";
import { UserData } from "../../models/user-data";
import ReviewCard from "../../components/public-profile/ReciewCard";
import { Review } from "../../models/review";
import { FaGraduationCap } from "react-icons/fa";
import PrimaryButton from "../../components/buttons/primary-button";
import { FiCornerDownRight } from "react-icons/fi";
import DialogWrapper from "../../components/dialogs/dialog-wrapper";
import RequestQuote from "../../components/request-quote/request-quote";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import { Link, navigate } from "gatsby";
import AttachmentHandler from "../../components/attachment-handler/attachment-handler";
import { OpenRoute } from "../../components/app-routes/app-routes";
import SEO from "../../components/seo/seo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import { Dispatch } from "redux";
import { UserActionTypes } from "../../redux/types/user-type";
import { users } from "../../utils/firebase.utils";
import { updateUser } from "../../redux/actions/user-actions";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import SecondaryButton from "../../components/buttons/secondary-button";
import FavoriteTasker from "../../components/my-account-components/favorite-taskers/favorite-tasker";

// import App from '../app';

const styles = makeStyles((theme) => ({
  paper: {
    padding: "14px 24px",
    width: "300px",
    background: "white",
    borderRadius: "10px",
    height: "fit-content",
    marginBottom: "20px",
  },
  badge: {
    background: "white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    border: "2px solid rgb(211,211,211,0.5)",
    padding: "2px",
    marginRight: "15px",
  },
  flex: {
    display: "flex",
  },
}));

const PublicProfile = (props) => {
  const params = useParams();
  const [user, setUser] = useState<UserData>(null);
  const [reviews, setReviews] = useState<Review[]>(null);
  const [showReviews, setShowReviews] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogg, setOpenDialogg] = React.useState("");
  const isBrowser = typeof window !== "undefined";

  const handleOpen = (type) => {
    setOpenDialogg(type);
  };

  const handleClosee = () => {
    setOpenDialogg("");
  };
  const currentUser = auth?.currentUser;
  const uid = params["id"];
  const banckAccount = user?.bankAccountEnabled;
  const { userData, isVerified, isAuthenticated } = useSelector(
    (state: RootState) => state.userData
  );
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const route = () => {
    currentUser ? (
      setOpenDialog(true)
    ) : (
      <Link to={`/login?redirect=/profile/${uid}`} />
    );
  };

  const getUserData = async () => {
    users
      .where("slug_title", "==", `/${params["id"]}`)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            setUser(new UserData(data));
          });
        }
      });
  };

  const getTask = async () => {
    const tasksList = await users
      .where("slug_title", "==", `/${params["id"]}`)
      .get();

    if (!tasksList.empty) {
      let t: UserData;
      tasksList.docs.forEach(async (doc) => {
        let data = doc.data();
        t = new UserData(data);
        data.id = doc.id;
      });

      if (t) {
        setUser(t);
      }
    }
  };

  const getUserReviews = async () => {
    if (user) {
      await users
        .doc(user.uid ?? "empty")
        .collection("reviews")
        .orderBy("createAt", "desc")
        .get()
        .then((snapshot) => {
          let array: Review[] = [];
          snapshot.forEach((doc) => {
            array.push(new Review(doc.data()));
          });
          if (array.length > 0) {
            setReviews(array);
          }
        });
    }
  };

  useEffect(() => {
    getUserData();
  }, [params["id"]]);

  useEffect(() => {
    getUserReviews();
  }, [user]);

  const classes = styles();
  const theme = useTheme();

  const addFavoriteTasker = async (values: Record<string, any>) => {
    try {
      await users
        .doc(userData.uid)
        .collection("favoriteTaskers")
        .doc(user.uid)
        .set(
          {
            uid: values.uid,
            firstName: values.firstName,
            lastName: values.lastName,
            profilePicture: values.profilePicture,
            workerReviewIndex: { ...values?.workerReviewIndex },
            slug_title: values?.slug_title,
          },
          { merge: true }
        );

      userData.favoriteTaskers.push(user.uid);
      await users
        .doc(userData.uid)
        .set({ favoriteTaskers: userData.favoriteTaskers }, { merge: true });
      const doc = await users
        .doc(userData.uid)
        .get()
        .then(() => {
          dispatch(updateUser(userData));
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeFavoriteTasker = async (taskerID: string) => {
    try {
      let filtered = userData.favoriteTaskers.filter(function (
        taskerID,
        index,
        arr
      ) {
        return taskerID != user.uid;
      });
      userData.favoriteTaskers = filtered;
      await users
        .doc(userData.uid)
        .set({ favoriteTaskers: filtered }, { merge: true })
        .then(() => {
          dispatch(updateUser(userData));
        });

      await users
        .doc(userData.uid)
        .collection("favoriteTaskers")
        .doc(taskerID)
        .delete()
        .then(() => {
          dispatch(updateUser(userData));
        });
    } catch (error) {
      console.log("##");
      console.log("error", error);
    }
  };

  return (
    <>
      {!user && (
        <div
          style={{
            backgroundColor: "#F7F8FB",
            width: "100%",
            height: "100%",
            marginTop: "2000px",
          }}
        ></div>
      )}

      {user && (
        <div style={{ zIndex: 1000 }}>
          <SEO
            title={"Sydetasker - Post. Hire. Done."}
            pathname={"/"}
            description={""}
          />
          {user && (
            <Container
              style={{
                marginTop: "100px",
                display: "flex",
                minHeight: "calc(100vh - 50px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  userId={user.slug_title}
                  name={user.name}
                  profilePicture={user.profilePicture}
                  size={100}
                />
                <div style={{ height: "30px" }} />

                {currentUser && currentUser?.uid == user?.uid ? (
                  ""
                ) : banckAccount && isAuthenticated ? (
                  <PrimaryButton
                    icon={
                      <FiCornerDownRight
                        size={18}
                        style={{ strokeWidth: "4" }}
                      />
                    }
                    onClick={() => route()}
                  >
                    Request Quote
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    icon={
                      <FiCornerDownRight
                        size={18}
                        style={{ strokeWidth: "4" }}
                      />
                    }
                    onClick={() => navigate("/login")}
                  >
                    Request Quote
                  </PrimaryButton>
                )}
                {currentUser && user && currentUser.uid == user.uid ? (
                  <>
                    <SecondaryButton
                      background={theme.palette.primary.main}
                      textColor={"white"}
                      style={{ width: "100%" }}
                      onClick={() => handleOpen("fav")}
                    >
                      {userData?.favoriteTaskers?.length} Favorite Taskers
                    </SecondaryButton>
                    {userData?.favoriteTaskers?.length > 0 && (
                      <DialogWrapper
                        root
                        title={"Favorites"}
                        onClose={handleClosee}
                        open={openDialogg === "fav"}
                      >
                        <div style={{ height: "500px" }}>
                          <div style={{ height: "2px" }} />
                          <FavoriteTasker />
                        </div>
                      </DialogWrapper>
                    )}{" "}
                  </>
                ) : (
                  <>
                    {userData?.favoriteTaskers?.includes(user.uid) ? (
                      <>
                        <SecondaryButton
                          background={theme.palette.primary.main}
                          textColor={"white"}
                          style={{ width: "100%", marginTop: "10px" }}
                          onClick={() => removeFavoriteTasker(user.uid)}
                        >
                          <FavoriteBorderIcon
                            style={{ height: "16.5px", paddingTop: "2px" }}
                          />{" "}
                          Remove From favorites{" "}
                        </SecondaryButton>
                      </>
                    ) : (
                      user?.verifiedStatus == "verified" &&
                      currentUser && (
                        <SecondaryButton
                          background={theme.palette.primary.main}
                          textColor={"white"}
                          style={{ width: "100%", marginTop: "10px" }}
                          onClick={() =>
                            addFavoriteTasker({
                              firstName: user?.firstName,
                              uid: user.uid,
                              lastName: user.lastName,
                              profilePicture: user.profilePicture,
                              workerReviewIndex: { ...user?.workerReviewIndex },
                              slug_title: user?.slug_title,
                            })
                          }
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/images%2Ficons8-star-50%20(2).png?alt=media&token=08f16c22-599e-4b26-afea-f24b1ca09941"
                            style={{ height: "16.5px", paddingTop: "2px" }}
                          />{" "}
                          Add To Favorites
                        </SecondaryButton>
                      )
                    )}
                  </>
                )}
              </div>
              <div style={{ width: "30px" }} />
              <div style={{ marginRight: "30px" }}>
                <Text variant={"h1"} bold>
                  {user.name}
                </Text>
                <Text
                  variant={"body2"}
                  medium
                  style={{ width: "350px", color: "#707070" }}
                >
                  {user.tagLine}
                </Text>
                <div style={{ height: "25px" }} />
                <div className={classes.paper}>
                  <Text variant={"body2"} bold style={{ marginBottom: "10px" }}>
                    About
                  </Text>
                  <Text
                    variant={"subtitle2"}
                    regular
                    style={{ color: "#707070" }}
                  >
                    <ReadMore maxLength={120}>{user.about}</ReadMore>
                  </Text>
                </div>
                <div className={classes.paper}>
                  <Text variant={"body2"} bold style={{ marginBottom: "10px" }}>
                    Badges
                  </Text>
                  {Array.isArray(user.badges) && user.badges.length > 0 ? (
                    user.badges.map((v, i) => {
                      {
                        switch (v.name) {
                          case "Insurance":
                            return (
                              <div
                                key={`${v}-${i.toString()}`}
                                className={classes.flex}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  loading={"lazy"}
                                  src={Insurance}
                                  className={classes.badge}
                                  alt={"badge"}
                                />
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "#707070" }}
                                >
                                  Insurance Verified
                                </Text>
                              </div>
                            );
                          case "ID":
                            return (
                              <div
                                key={`${v}-${i.toString()}`}
                                className={classes.flex}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  loading={"lazy"}
                                  src={ID}
                                  className={classes.badge}
                                  alt={"badge"}
                                />
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "#707070" }}
                                >
                                  ID Verified
                                </Text>
                              </div>
                            );
                          case "BackgroundCheck":
                            return (
                              <div
                                key={`${v}-${i.toString()}`}
                                className={classes.flex}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  loading={"lazy"}
                                  src={BackgroundCheck}
                                  className={classes.badge}
                                  alt={"badge"}
                                />
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "#707070" }}
                                >
                                  Background Verified
                                </Text>
                              </div>
                            );
                          case "phone":
                            return (
                              <div
                                key={`${v}-${i.toString()}`}
                                className={classes.flex}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  className={classes.badge}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {" "}
                                  <Icon
                                    fill={theme.palette.primary.main}
                                    name={"phone-outline"}
                                    size="medium"
                                  />
                                </div>
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "#707070" }}
                                >
                                  Mobile Verified
                                </Text>
                              </div>
                            );
                          case "facebook.com":
                            return (
                              <div
                                key={`${v}-${i.toString()}`}
                                className={classes.flex}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  loading={"lazy"}
                                  src={FacebookIcon}
                                  className={classes.badge}
                                  alt={"badge"}
                                  style={{ background: "rgb(66, 103, 178)" }}
                                />
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "#707070" }}
                                >
                                  Facebook Verified
                                </Text>
                              </div>
                            );
                          case "google.com":
                            return (
                              <div
                                key={`${v}-${i.toString()}`}
                                className={classes.flex}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  loading={"lazy"}
                                  src={GoogleIcon}
                                  className={classes.badge}
                                  alt={"badge"}
                                />
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "#707070" }}
                                >
                                  Google Verified
                                </Text>
                              </div>
                            );
                          default:
                            return null;
                        }
                      }
                    })
                  ) : (
                    <Text variant={"body2"} style={{ color: "#707070" }} bold>
                      No badges!
                    </Text>
                  )}
                </div>
                <div className={classes.paper}>
                  <Text variant={"body2"} bold style={{ marginBottom: "10px" }}>
                    Education
                  </Text>
                  {Array.isArray(user?.education) &&
                  user?.education?.length > 0 ? (
                    <div>
                      {user.education.map((v, i) => {
                        return (
                          <div
                            key={`${v}-${i.toString()}`}
                            style={{
                              marginRight: "8px",
                              marginTop: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FaGraduationCap size={18} />
                            <div style={{ width: "15px" }} />
                            <Text variant={"subtitle2"} medium component={"p"}>
                              {v}
                            </Text>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Text variant={"body2"} style={{ color: "#707070" }} bold>
                      No education info!
                    </Text>
                  )}
                </div>
                <div className={classes.paper}>
                  <Text variant={"body2"} bold style={{ marginBottom: "10px" }}>
                    Skills
                  </Text>
                  {Array.isArray(user.skills) && user.skills.length > 0 ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {user.skills.map((v, i) => {
                        return (
                          <div
                            key={`${v}-${i.toString()}`}
                            style={{
                              background: "rgb(245,245,245)",
                              padding: "8px 12px",
                              borderRadius: "8px",
                              marginRight: "8px",
                              marginTop: "8px",
                            }}
                          >
                            <Text variant={"subtitle2"} medium component={"p"}>
                              {v}
                            </Text>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Text variant={"body2"} style={{ color: "#707070" }} bold>
                      No skills info!
                    </Text>
                  )}
                </div>
                {user.portfolio.length > 0 ? (
                  <div className={classes.paper} style={{ height: "200px" }}>
                    <Text variant={"body2"} style={{ color: "#707070" }} bold>
                      Portfolio
                    </Text>
                    <AttachmentHandler images={user.portfolio} />
                  </div>
                ) : (
                  <div className={classes.paper} style={{ marginTop: "20px" }}>
                    <Text variant={"body2"} bold style={{ color: "#707070" }}>
                      No portfolio yet!
                    </Text>
                  </div>
                )}
              </div>

              <div>
                <div style={{ height: "77px" }} />

                <div
                  className={classes.paper}
                  style={{ padding: "0", margin: "0", width: "348px" }}
                >
                  {user && (
                    <ReviewsBox
                      profilePage={true}
                      setShowReviews={setShowReviews}
                      taskOwnerReviewIndex={
                        user.taskOwnerReviewIndex
                          ? user.taskOwnerReviewIndex
                          : ""
                      }
                      workerReviewIndex={
                        user.workerReviewIndex ? user.workerReviewIndex : ""
                      }
                      completion_rate={
                        user.completion_rate ? user.completion_rate : ""
                      }
                      completion_rate_poster={
                        user.completion_rate_poster
                          ? user.completion_rate_poster
                          : ""
                      }
                      completion_rate_tasker={
                        user.completion_rate_tasker
                          ? user.completion_rate_tasker
                          : ""
                      }
                    />
                  )}
                </div>

                <div style={{ height: "35px" }} />

                {Array.isArray(reviews) && reviews.length > 0 ? (
                  <div>
                    <div>
                      {Array.isArray(reviews) && showReviews == 0 ? (
                        <div>
                          {reviews.map((r, i) => {
                            return r.text && r.type == "owner" ? (
                              <div key={`${r.taskTitle}-${i.toString()}`}>
                                <ReviewCard profile={false} review={r} />
                              </div>
                            ) : null;
                          })}
                        </div>
                      ) : null}
                      {Array.isArray(reviews) && showReviews == 1 ? (
                        <div>
                          {reviews.map((r, i) => {
                            return r.text && r.type == "worker" ? (
                              <div key={`${r.taskTitle}-${i.toString()}`}>
                                <ReviewCard profile={false} review={r} />
                              </div>
                            ) : null;
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "14px 24px",
                      width:
                        isBrowser &&
                        window.location.pathname.includes("/profile")
                          ? "400px"
                          : "300px",
                      background: "white",
                      borderRadius: "10px",
                      height: "fit-content",
                      marginBottom: "20px",
                    }}
                  >
                    <Text variant={"body2"} bold style={{ color: "#707070" }}>
                      No reviews yet!
                    </Text>
                  </div>
                )}
              </div>
            </Container>
          )}
          {banckAccount && (
            <DialogWrapper
              onClose={handleClose}
              open={openDialog}
              root
              title={"Request Quote"}
            >
              <RequestQuote user={user} onClose={handleClose} />
            </DialogWrapper>
          )}
        </div>
      )}
    </>
  );
};

const App = () => {
  const location = useLocation();
  return (
    <div className="content">
      <Router location={location}>
        <OpenRoute path="/profile/:id" component={PublicProfile} />
      </Router>
    </div>
  );
};

export default App;
