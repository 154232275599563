import React, { useEffect, useState } from "react";
import Text from "../../data-display/text";
import { IconButton, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/index.reducers";
import { Dispatch } from "redux";
import { UserActionTypes } from "../../../redux/types/user-type";
import { users } from "../../../utils/firebase.utils";
import { UserData } from "../../../models/user-data";
import Avatar from "../../../components/avatar/avatar";
import StarRating from "../../../components/reviews-ratings/star-rating";
import NoTasksTasker from "../../../images/images/my_tasks_worker_empty.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useUsersData from "../../../hooks/useUsersData";

const styles = makeStyles((theme) => ({
  root: {
    background: "white",
    transition: "0.3s",
    border: "1.5px solid rgb(245,245,245)",
    borderBottom: "none",
    marginBottom: "20px",
  },
  miniBtn: {
    background: "#e5e1ff",
    color: theme.palette.primary.main,
    marginTop: "15px",
    alignSelf: "flex-end",
  },
  taskerCard: {
    display: "flex",
    padding: "12px 18px",
    borderBottom: "1.5px solid rgb(245, 245, 245)",
  },
  greenTick: {
    padding: "2px 15px",
    background: "#34c4a2",
    borderRadius: "50px",
    alignSelf: "flex-end",
  },
}));

const FavoriteTasker = (props) => {
  const classes = styles();
  const theme = useTheme();
  const { userData } = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const [fav, setFav] = useState<UserData[]>([]);
  const [user, setUser] = useState<UserData[]>([]);
  const {getUser} = useUsersData();


  const next = props.next;

  const getUserData= async(uid)=>{
        const data = await getUser(uid);
        user.push(new UserData(data));
}


  const getFavoriteTaskers = async () => {
    try {
      let usersList: UserData[] = [];
      await users
        .doc(userData.uid)
        .collection("favoriteTaskers")
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.docs.forEach((doc) => {
              const data = doc.data();
              getUserData(data?.uid)
              usersList.push(new UserData(data));
            });
            setFav(usersList);
            console.log("userslist", usersList);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getFavoriteTaskers();
  }, []);

  return (
    <div>
      <div
        style={{
          padding: "15px",
          borderBottom: "1.5px solid rgb(245,245,245)",
        }}
      >
        <Text
          variant={"subtitle2"}
          medium
          style={{ color: theme.palette.primary.main }}
        >
          My Favorite list
        </Text>
        <Text variant={"caption"} medium style={{ color: "grey" }}>
          You can see all your favorite Taskers !
        </Text>
      </div>
      {fav.length > 0  ? (
        fav.map((tasker, index) => (
          <div className={classes.taskerCard}>
            <Avatar
              userId={user[index]?.uid == tasker?.uid ? user[index]?.slug_title:tasker?.slug_title}
              profilePicture={tasker.profilePicture}
              size={30}
            />
            <div style={{ width: "15px" }} />
            <div>
              <Text variant={"subtitle2"} medium>
                {tasker.name}
              </Text>
              <StarRating
                totalAverage={tasker.workerReviewIndex?.totalAverage}
                totalCount={tasker.workerReviewIndex?.totalCount}
              />
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px 0",
          }}
        >
          <img
            src={NoTasksTasker}
            style={{
              display: " flex",
              justifyContent: "center",
              marginLeft: "4px",
              marginBottom: "40px",
            }}
          ></img>
          <Text variant={"body2"} medium>
            You don't have any favorites
          </Text>
        </div>
      )}
    </div>
  );
};

export default FavoriteTasker;
