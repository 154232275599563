import React, { useEffect, useState } from 'react';
import { GrayColor } from '../../constants/constant-ui';
import Text from '../data-display/text';
import moment from 'moment';
import { Calendar, Globe, MapPin } from 'react-feather';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Task } from '../../models/task';
import classNames from 'classnames';
import MiniButton from '../buttons/mini-button';
import { FiCheck } from 'react-icons/fi';
import axios from 'axios';
import { baseUrl } from '../../utils/constant.urls';
import { useParams } from '@reach/router';
import firebase, { auth, tasks } from '../../utils/firebase.utils';
import { UserData } from '../../models/user-data';

const useStyles = makeStyles((theme) => ({
     taskCard: {
          display: 'flex',
          flexDirection: 'column',
          background: 'white',
          position: 'relative',
          borderRadius: '6px',
          boxShadow: '0 1px 2px rgb(0,0,0,0.1)',
          transition: '0.3s',
          border: '1.5px solid rgb(245,245,245)',
          marginBottom: '20px',
     },
     miniBtn: {
          background: '#e5e1ff',
          color: theme.palette.primary.main,
          marginTop: '15px',
     },
     greenTick: {
          padding: '2px 15px',
          background: '#34c4a2',
          borderRadius: '50px',
     }
}));

const TaskRequestQuote = ({ task, invited }: { task: Task, invited: boolean }) => {
     const classes = useStyles();
     const params = useParams();
     const [taskerInvited, setInvited] = useState(invited);
     const currentUser = auth?.currentUser;


     const inviteTasker = () => {
          if (params['id']) {
               setInvited(true);
               auth.currentUser.getIdToken()
                    .then((userToken) => {
                         return axios.post(
                              `${baseUrl}/inviteToTask`,
                              { taskId: task.id, userId: params['id'] },
                              {
                                   headers: {
                                        Authorization: userToken,
                                   },
                              },
                         );
                    })
                    .then(() => {
                         tasks.doc(task.id)
                              .update({
                                   'invitedTaskers': firebase.firestore.FieldValue.arrayUnion(params['id'])
                              });
                    })
                    .catch(e => {
                         console.log(e);
                    });
          }
     };

     return (
          <div className={classes.taskCard}
          >
               <div style={{ display: 'flex', padding: '14px 16px' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                         <Text variant={'overline'}
                              style={{ color: 'grey', lineHeight: 1 }}>{task.category ??
                                   'Others'}</Text>
                         <div style={{ height: '4px' }} />
                         <Text variant={'body2'} style={{ lineHeight: 1 }} bold>{task.title}</Text>
                         <div style={{ height: '8px' }} />
                         {
                              task.isRemote ?
                                   <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Globe color={GrayColor} size={10} />
                                        <div style={{ width: '12px' }} />
                                        <Text variant={'caption'} style={{ color: 'grey' }}
                                             medium>Remote</Text>
                                   </div>
                                   :
                                   <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <MapPin color={GrayColor} size={10} />
                                        <div style={{ width: '12px' }} />
                                        <Text variant={'caption'} style={{ color: 'grey' }}
                                             medium>{task.city}</Text>
                                   </div>
                         }
                         <div style={{ height: '4px' }} />
                         <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Calendar color={GrayColor} size={10} />
                              <div style={{ width: '14px' }} />
                              <Text variant={'caption'} style={{ color: 'grey' }} medium>{moment(
                                   task.dateToStart).format('dddd, MMM DD')}</Text>
                         </div>
                    </div>
                    <div style={{ marginLeft: '24px', marginTop: '15px', height: 'fit-content' }}>
                         {taskerInvited ?
                              <span className={classes.greenTick}>
                                   <FiCheck style={{ stroke: 'white' }} />
                              </span>
                              :
                              <MiniButton className={classes.miniBtn}  onClick={inviteTasker}>Invite</MiniButton>
                         }
                    </div>
               </div>
          </div>
     );
};

export default TaskRequestQuote;